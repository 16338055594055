<vue-page-header appName="{{ 'title.applicationName' | translate | async }}"
  navSection="{{ 'title.alerts' | translate | async }}" [enableBack]="true" [pageTitle]="pageTitle"></vue-page-header>
<app-page-body>
  <vue-section-header class="details-header-wrapper">
    <h2 class="details-header-text">{{ "title.alertDetails" | translate | async }}</h2>
    <vue-flat-button (click)="OpenCalendar()">{{ 'label.calendar' | translate | async }}</vue-flat-button>
  </vue-section-header>
  <vue-section [loading]="pageLoading">
    <div style="display: flex;">
      <div class="detail-column-header">
        <h3>{{ "title.summary" | translate | async }}</h3>
      </div>
      <div class="detail-column-header">
        <h3>{{ "title.details" | translate | async }}</h3>
      </div>
    </div>
    <div class="grid_class">
    <div class="details-wrapper">
      <div class="item1 detail-column-wrapper innerclassPadding ">
        <div class="details-child-wrapper0 detail-column-section">
          <vue-detail-header>{{ "title.alertType" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ alertDetails.type }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper0   detail-column-section">
          <vue-detail-header>{{ "title.priority" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ alertDetails.priority }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper0  detail-column-section">
          <vue-detail-header>{{ "title.status" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ alertDetails.status }}</vue-detail-content>
        </div>
       
      </div>
      <div class="item2 detail-column-wrapper ">

        <div class="details-child-wrapper2 detail-column-section">
          <vue-detail-header>{{ "title.createdOn" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedCreatedOn }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper2 detail-column-section">
          <vue-detail-header>{{ "title.resolvedOn" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedResolvedOn }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper2 detail-column-section">
          <vue-detail-header>{{ "title.resolvedBy" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ resolvedBy }}</vue-detail-content>
        </div>
      </div>
      <div class="item3 innerclassPadding " style="margin-top: 39px;  height: max-content; width: 190%;">
        <vue-detail-header>{{ "title.summary" | translate | async }}</vue-detail-header>
        <vue-detail-content>{{ alertDetails.summary }}</vue-detail-content>
      </div>
      
      <!-- <mat-divider vertical></mat-divider> -->
      
    </div>
    <mat-divider vertical></mat-divider>
    <div class="details-wrappers">
     
      <!-- <mat-divider vertical></mat-divider> -->
      <div class="detail-column-wrapper innerclassCreateddate">
        <div class="details-child-wrapper1 detail-column-section">
          <vue-detail-header>{{ "title.poolName" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ poolName }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper1 detail-column-section">
          <vue-detail-header>{{ "title.client" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="clients?.length; else clientdash">
            <vue-detail-content *ngFor="let client of clients">
              {{ client.name }}
            </vue-detail-content>
          </ng-container>
          <ng-template #clientdash>
            <p>–</p>
          </ng-template>
        </div>
       


      </div>
      <div class="detail-column-wrapper innerclassexam ">
        <div class="details-child-wrapper2 detail-column-section">
          <vue-detail-header>{{ "title.threshold" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ alertDetails.threshold }} %</vue-detail-content>
        </div>
        <div class="details-child-wrapper2 detail-column-section">
          <vue-detail-header>{{ "title.email" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ alertDetails.email }}</vue-detail-content>
        </div>
      
      </div>
      <div class="detail-column-wrapper innerclassCreateddate">
        <div class="details-child-wrapper1 detail-column-section">
          <vue-detail-header>{{ "title.exams" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="examsToDisplay.length; else dash">
            <p *ngFor="let exam of examsToDisplay" class="exam-name" matTooltip="{{ exam.name }}">{{ exam.name }}</p>
            <button *ngIf="moreThanFiveExams()" (click)="toggleExamsDisplay()" class="exam-show-toggle-button">
              <ng-container *ngIf="examsToDisplay.length > 5">
                {{'button.showLess' | translate | async }}
              </ng-container>
              <ng-container *ngIf="examsToDisplay.length <= 5">
                {{'button.showMore' | translate | async }}
              </ng-container>
            </button>
          </ng-container>
          <ng-template #dash>
            <p>–</p>
          </ng-template>
        </div>
        <div class="details-child-wrapper1 detail-column-section">
          <vue-detail-header>{{ "title.queue" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ queueName }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper1 detail-column-section">
          <vue-detail-header>{{ "title.alertDateRange" | translate | async }}</vue-detail-header>
          <vue-detail-content>{{ formattedAlertStartDate }} – {{ formattedAlertEndDate }}</vue-detail-content>
        </div>
        <div class="details-child-wrapper1 detail-column-section">
          <vue-detail-header>{{ "title.alertTimeRange.utc" | translate | async }}</vue-detail-header>
          <ng-container *ngIf="alertTimeRangeToDisplay.length; else dash">
            <p *ngFor="let time of alertTimeRangeToDisplay" class="exam-name">{{ time.startTime }} - {{ time.endTime }}</p>
            <button *ngIf="moreThanFiveAlertTimeRange()" (click)="toggleAlertTimeRangeDisplay()" class="exam-show-toggle-button">
              <ng-container *ngIf="alertTimeRangeToDisplay.length > 5">
                {{'button.showLess' | translate | async }}
              </ng-container>
              <ng-container *ngIf="alertTimeRangeToDisplay.length <= 5">
                {{'button.showMore' | translate | async }}
              </ng-container>
            </button>
          </ng-container>
          <ng-template #dash>
            <p>–</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  </vue-section>
</app-page-body>