import { Component, ElementRef, Input, TemplateRef, ViewChild } from '@angular/core';
import { utc } from 'moment';
import { take } from 'rxjs/operators';
import { calculateAlertUtilizationPercentage } from 'components/common/pools/utils/calculate-alert-utilization-percentage';
import { ABBREV_MONTH_FORMAT, EXCHANGE_FORMAT } from 'constants/date-formats';
import { TranslatePipe } from 'pipes/translate.pipe';
import { VuePopoverService } from 'vue/components/vue-popover-module/vue-popover.service';
import { MatDialog } from '@angular/material/dialog';
import { LoadingDialogComponent, LoadingDialogInput, loadingDialogConfig } from 'components/dialogs/loading-dialog/loading-dialog.component';
import { AlertResolveComponent, AlertResolveInputs, alertResolveModalConfig } from 'components/common/poolconsumption/alert-resolve/alert-resolve.component';
import { CalendarOccuranceService } from 'services/api/calendar-occurance.service';

@Component({
  selector: 'app-alert-resolve-calendar-item',
  templateUrl: './alert-resolve-calendar-item.component.html',
  styleUrls: ['./alert-resolve-calendar-item.component.scss']
})
export class AlertResolveCalendarItemComponent {
  /**
    * 
    */
  @Input() public isWeek = false;

  /**
   * Metric details for the individual day
   */

  @Input() alertSpecificData: any;

  @Input() id: any

  @Input() public metricItem: any = {
    date: '',
    inCurrentMonth: true,
    isHide: true,
    netQueueAva: '',
    poolAva: '',
    poolUtilization: ''
  }

  @ViewChild('dailyPopoverTemplate') public popoverTemplate!: TemplateRef<unknown>;

  /**
   * Date title to display
   */
  public titleDisplay = '';

  /**
   * Utilization calculation
   */
  public utilization = '';

  /**
   * Boolean to toggle outside of month related styles
   */
  public isOutsideMonth = false;

  /**
   * Is the current item's timestamp today's date
   */
  public isTodaysDate = false;

  /**
   * Current day formatted as a date for the popover content
   */
  public popoverDate = '';

  /**
   * Label for the calendar item, read out by screen readers instead of `titleDisplay`
   */
  public screenReaderLabel = '';

  public constructor(
    private popoverService: VuePopoverService,
    private dialog: MatDialog,
    private hostElement: ElementRef,
    private translatePipe: TranslatePipe,
    private capacityapi: CalendarOccuranceService
  ) { }

  public ngOnInit(): void {
    const { timestamp, poolRegistrations, poolAvail, isHide } = this.metricItem;
    const itemMoment = utc(timestamp);

    const utilizationPercentage = calculateAlertUtilizationPercentage(poolRegistrations, poolAvail || 0);

    this.popoverDate = itemMoment.format(EXCHANGE_FORMAT);
    this.utilization = utilizationPercentage.toFixed(2) + '%';
    this.screenReaderLabel = `${itemMoment.format(ABBREV_MONTH_FORMAT)} ${itemMoment.date()}`;
    var alertStartDate = utc(this.alertSpecificData.date);
    var alertEndDate = utc(this.alertSpecificData.endDate);

    if (!isHide) {
      // Set week class & display
      if (this.isWeek) {
        var alertStartWeek = alertStartDate.week();
        var itemWeek = itemMoment.week();
        var weekToDisplay = (itemWeek - alertStartWeek) + 1;

        this.translatePipe.transform('title.weekOf', weekToDisplay.toString())
          .pipe(take(1))
          .subscribe((titleDisplay) => {
            this.titleDisplay = titleDisplay;
            this.screenReaderLabel = titleDisplay;
          });
        return;
      }
    }

    const itemDay = itemMoment.date();

    const itemMonthEnd = itemMoment.clone().endOf('month').date();

    const isFirstDay = itemDay === 1 || (itemMoment.toISOString() == alertStartDate.toISOString());
    const isLastDay = itemDay === itemMonthEnd || (itemMoment.toISOString() == alertEndDate.toISOString());

    if (!this.metricItem.inCurrentMonth) {
      this.titleDisplay = isFirstDay || isLastDay ?

        `${itemMoment.format(ABBREV_MONTH_FORMAT)} ${itemDay}` :
        String(itemDay);
      this.isOutsideMonth = true;
    } else {
      this.titleDisplay = itemMoment.format('D');
      this.isTodaysDate = itemMoment.isSame(utc(), 'day');
    }
  }

  /**
   * Closes any existing popovers and opens a new one
   */
  public openPopover(event: any): void {
    if (!this.metricItem.isHide && !this.isWeek) {
      this.popoverService.closeAll();
      // this.popoverService.open(this.popoverTemplate, this.hostElement);
      const loader = this.dialog.open<LoadingDialogComponent, LoadingDialogInput>(LoadingDialogComponent, {
        ...loadingDialogConfig,
       
        data: {
          title: 'title.loading',
          subtitle: 'subtitle.pleaseWait',
        },
      });

      // innner Dialog box
      this.capacityapi.getCalendarCapacity(this.id.key.id, event).subscribe((result: any) => {
        let record: any = {
          'data': result, 
          'id': this.id.key.id,
          'status':this.id.key.status
        }
        loader.close();
        let dialogRef = this.dialog.open<AlertResolveComponent, AlertResolveInputs>(AlertResolveComponent, {
          ...alertResolveModalConfig,
          panelClass:'custom-dialog-container',
          data: record
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
          }
        });

      }, (error: unknown) => {
        loader.close();
      });
    }
  }
}
