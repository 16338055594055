import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CalendarOccuranceService } from 'services/api/calendar-occurance.service';
import { VUE_DIALOG_CONFIG } from 'vue/utilities/vue-dialog-config';
import { Location } from '@angular/common';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent{
  loadingStatus=false;
 resolvedStatus="resolved"
  constructor(private dialogRef: MatDialogRef<ConfirmationDialogComponent>,@Inject(MAT_DIALOG_DATA) public data: any,
  private capacityapi: CalendarOccuranceService,  private location:Location,) { }

  public closeDialog(): void {
    this.dialogRef.close();
    
  }

  confirmDialogue(){
    this.loadingStatus=true;
   let payload={
      "status":this.resolvedStatus
    }
    this.capacityapi.resolveUpdatedById(this.data.id,payload).subscribe((result:any)=>{
      this.loadingStatus=false;
      this.dialogRef.close();
      this.location.back()
      
    } , (error: unknown) => {
      this.loadingStatus=false;
      this.location.back()
    })
    ;
  }

}
export const confirmationModalConfig = {
  ...VUE_DIALOG_CONFIG,
};
