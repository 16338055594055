import { Injectable } from '@angular/core';
import { CapacityMicroservice } from './microservices/capacity.microservice';

@Injectable({
  providedIn: 'root'
})
export class CalendarOccuranceService {

  constructor(private api: CapacityMicroservice) {
   }

   getCalendarCapacity(id:any,date:any){
    const path=`metrics/slotWiseCapacities/${id}?pcAlertDate=${date}`
    return this.api.get<any>(path);
   }

   updateCalendarCapacity(id:any,payload:any){
    const path=`metrics/updateSlotWiseCapacities/${id}`
    return this.api.patch<any,unknown>(path,payload);
   }

   resolveUpdatedById(id:any,payload:any){
    const path=`resolve/alert/${id}`
    return this.api.patch<any,unknown>(path,payload);
   }

  //  updateOccuranceofAlert(){
  //   const payload = {
  //     "date": this.data.data.date,
  //     "occrStatus": this.data.data.occrStatus,
  //     "slotsWiseCapacity": this.occurrenceForm.get('timeSlots')?.value
  //   }
  //  }
  
  
}
