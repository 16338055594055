import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Moment, utc } from 'moment';
import { CapacityMetricItem } from 'api/types';
import { calculateAlertUtilizationPercentage } from 'components/common/pools/utils/calculate-alert-utilization-percentage';
import { EXCHANGE_FORMAT, FULL_MONTH_YEAR_FORMAT } from 'constants/date-formats';
import { AddAlertPoolDataService } from 'services/add-alert-pool-data.service';

export interface CapacityInfo {
  capacity: number;
  registrations: number;
  utilization: string;
  weekNumber?: number;
  isHide?: boolean;
}

export type DailyMetricItem = CapacityMetricItem & {
  inCurrentMonth?: boolean;
  netQueueAva: any;
  poolAvail: any;
  poolRegistrations: any;
  isHide: boolean;
}

@Component({
  selector: 'app-app-alert-resolve-chart-monthly',
  templateUrl: './app-alert-resolve-chart-monthly.component.html',
  styleUrls: ['./app-alert-resolve-chart-monthly.component.scss']
})
export class AppAlertResolveChartMonthlyComponent implements OnInit {
  setDatePickerValues = true;
  @Output() setIntialdata = new EventEmitter();
  getAlertTimeRange: any = "Alerthours";
  @Input() public getCalendarDetails: any;

  @Input() public metricItems: any[] = [];

  @Input() alertSpecificData: any;
  /**
   * Currently selected month as a moment object
   */
  @Input() public selectedMonth: Moment = utc();

  /**
   * Metric items by the day instead of by the hour
   */
  id:any
  public dailyMetricItems: DailyMetricItem[] = [];

  /**
   * Metric item totals each week displayed on the calendar
   */
  public weeklyMetricItems: CapacityMetricItem[] = [];

  /**
   * Metric item totals for the selected month
   */
  public monthlyMetricItems: any = {
    netQueueAva: "",
    poolAva: "",
    poolUtilization: ""
  }
constructor(private alertapi:AddAlertPoolDataService){}
  public ngOnChanges(): void {
    // Build all metric times
    if (this.metricItems.length > 0) {
      this.buildDailyMetricItems();
      this.buildMonthlyMetricItems();
      this.buildWeeklyMetricItems();
    }
  }


  ngOnInit(): void {
    this.setIntialdata.emit(this.getAlertTimeRange == 'Alerthours' ? true : false)
    this.alertapi.calendarObjects$.subscribe(ele =>{
  this.id=ele;
    })
  }
  /**
   * Returns the display value for the selected month
   */
  public getMonthDisplay(): string {
    return utc(this.selectedMonth).format(FULL_MONTH_YEAR_FORMAT);
  }

  /**
   * Build a daily metric item
   */
  public buildDailyMetricItems(): void {
    // Remove time from date so we can sort the metric items by day
    let currentDate = utc(this.metricItems[0].date).format(EXCHANGE_FORMAT);
    let currentDatePoolAvail: number | null = 0;
    let currentDateRegistrations = 0;
    let netQueueAva: any;
    let isHide: boolean;

    const dailyMetricItems: DailyMetricItem[] = [];
    // Going through metricItems' hourly data and building an array that has metric items by the day
    this.metricItems.forEach((item: any, index: any) => {
      const itemDate = utc(item.date).format(EXCHANGE_FORMAT);
      const isLastItem = index === this.metricItems.length - 1;
      const isCurrentDay = itemDate === currentDate;

      /**
       * Check if this metric item is for the same day as the day we're currently adding data up for. If not,
       * push the data we've added up into weeklyMetricItems and make this metric item's day the new current day
       */
      if (isCurrentDay) {
        currentDatePoolAvail = currentDatePoolAvail === null ? item.poolAva : currentDatePoolAvail + (item.poolAva || 0);
        currentDateRegistrations += item.poolRegistrations || 0;
        isHide = item.isHide
        netQueueAva = item.netQueueAva || 0;
      } else {
        const inCurrentMonth = utc(currentDate).month() === this.selectedMonth.month();
        this.pushMetricItem(
          currentDatePoolAvail,
          currentDateRegistrations,
          currentDate,
          dailyMetricItems,
          inCurrentMonth,
          isHide,
          netQueueAva
        );

        // Update current info to be the first item of the next days info
        currentDate = itemDate;
        currentDatePoolAvail = item.poolAva || 0;
        currentDateRegistrations = item.poolRegistrations || 0;
        netQueueAva = item.netQueueAva || 0;
        isHide = item.isHide
      }

      /**
       * If this metric item is the last one, isCurrentDay is true so it will never hit the else block
       * that adds the current data to the daily array. This block adds it
       */
      if (isLastItem) {
        const inCurrentMonth = utc(currentDate).month() === this.selectedMonth.month();
        this.pushMetricItem(
          currentDatePoolAvail,
          currentDateRegistrations,
          currentDate,
          dailyMetricItems,
          inCurrentMonth,
          item.isHide,
          item.netQueueAva
        );
      }
    });

    this.dailyMetricItems = dailyMetricItems;
  }

  public buildMonthlyMetricItems(): void {
    this.monthlyMetricItems = {
      netQueueAva: this.alertSpecificData.totalNetQueueAvail,
      poolAvail: this.alertSpecificData.totalPoolAva,
      utilization: calculateAlertUtilizationPercentage(this.alertSpecificData.totalPoolRegistrations, this.alertSpecificData.totalPoolAva).toFixed(2) + '%'
    };
  }

  public buildWeeklyMetricItems(): void {
    // Get the week of the year for the first daily metric item, whatever week has Jan 1st is week 1
    let currentWeek = utc(this.dailyMetricItems[0].timestamp).week();
    let currentWeekPoolAvail = 0;
    let currentWeekRegistrations = 0;
    let netQueueAva: any = 0
    let isHide: boolean = true;
    let iscurrentmonth: boolean;
    const weeklyMetricItems: CapacityMetricItem[] = [];

    // Going through 'dailyMetricItems' daily data and building an array that has metric items by the week
    this.dailyMetricItems.forEach((item, index) => {
      const itemWeek = utc(item.timestamp).week();
      const isLastItem = index === this.dailyMetricItems.length - 1;
      const isInCurrentWeek = itemWeek === currentWeek;

      /**
       * Check if the this daily metric item is in the week we're currently adding data up for. If not,
       * push the data we've added up into weeklyMetricItems and make this daily metric item's week the new current week
       */
      if (isInCurrentWeek) {
        currentWeekPoolAvail +=  item.poolAvail || 0;
        currentWeekRegistrations += item.poolRegistrations || 0;
        netQueueAva += item.netQueueAva || 0;
        if (!item.isHide) {
          isHide = false;
        }
      } else {
        // Push the previous week's totals
        const prevWeekTimestamp = utc(item.timestamp).add(-7, 'days').format();
        this.pushMetricItem(currentWeekPoolAvail, currentWeekRegistrations, prevWeekTimestamp, weeklyMetricItems, iscurrentmonth, isHide, netQueueAva);
       
        currentWeek = itemWeek;
        currentWeekPoolAvail = item.poolAvail || 0;
        currentWeekRegistrations = item.poolRegistrations || 0
        netQueueAva = item.netQueueAva || 0;
        isHide = true;
      }

      /**
       * If this daily metric item is the last one, isCurrentDay is true  it will never hit the else block
       * that adds the current data to the weekly array. This block adds its
       */
      if (isLastItem) {
        const thisWeekTimestamp = utc(item.timestamp).day(0).format();
        this.pushMetricItem(currentWeekPoolAvail, currentWeekRegistrations, thisWeekTimestamp, weeklyMetricItems, iscurrentmonth, isHide, netQueueAva);
      }
    });

    this.weeklyMetricItems = weeklyMetricItems;
  }

  public pushMetricItem(
    poolAvail: number | null,
    poolRegistrations: number,
    timestamp: string,
    array: CapacityMetricItem[],
    inCurrentMonth?: boolean,
    isHide?: boolean,
    netQueueAva?: any
  ): void {
    const basicData = {
      poolAvail,
      poolRegistrations,
      timestamp,
      isHide,
      netQueueAva
    };
    const allData: any = inCurrentMonth !== null ? { ...basicData, inCurrentMonth } : basicData;
    array.push(allData);
    
  }

  getSelectedTimeRange() {
    this.setIntialdata.emit(this.getAlertTimeRange == 'Alerthours' ? true : false);
  }
}
